import styled from 'styled-components';
import { PrimaryButton } from '@app/modules/common/index';
import { AiOutlineClose } from 'react-icons/ai';

export const Description = styled.div`
  color: ${(props) => props.theme.black};
  font-size: 1.2rem;
  margin-top: 0.5rem;
`;
export const PopUpCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 26rem;
  border: 2px solid ${(props) => props.theme.primary};
  border-radius: 0.5rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.black};
`;

export const Title = styled.span`
  color: ${(props) => props.theme.black};
  font-weight: 700;
  font-size: 1.8rem;
  margin-left: auto;
  margin-right: auto;
`;

export const FailedButton = styled(PrimaryButton)`
  margin-top: 1rem;
  align-self: center;
`;
export const CrossWrapper = styled(AiOutlineClose)`
  font-size: 1.5rem;
  &:hover {
    color: ${(props) => props.theme.highlight};
    cursor: pointer;
  }
`;
export const PopUpHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
