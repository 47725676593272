import React from 'react';
import { CrossWrapper, Description, FailedButton, PopUpCard, PopUpHeader, Title } from './AlertPopUp.styles';
import { Translation } from '@app/modules/common/index';
import { NOTIFICATION_TYPE } from 'tatvarnsdk/Web';

export default function AlertPopUp({
  title,
  description1,
  description2,
  buttonText,
  buttonAction,
  setPopup,
  notificationType
}) {
  const getColor = () => {
    switch (notificationType) {
      case NOTIFICATION_TYPE.SUCCESS:
        return '#4fb200';
      case NOTIFICATION_TYPE.ERROR:
        return '#d70404';
      case NOTIFICATION_TYPE.WARNING:
        return '#f90';
      case NOTIFICATION_TYPE.DEFAULT:
        return '#036fe5';
      default:
        return '#036fe5';
    }
  };

  return (
    <PopUpCard style={{ border: `2px solid ${getColor()}` }}>
      <PopUpHeader>
        <Title>
          <Translation text={title} />
        </Title>
        <CrossWrapper onClick={() => setPopup(null)} />
      </PopUpHeader>
      <Description>
        <Translation text={description1} />
      </Description>
      <Description>
        <Translation text={description2} />
      </Description>
      {buttonAction && buttonText && <FailedButton onClick={buttonAction} text={buttonText} />}
    </PopUpCard>
  );
}
