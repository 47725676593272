import React, { memo, useEffect, useState } from 'react';
import { Loading } from '../common/index';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { selectCategoriesWithClasses, selectUserAlert, selectLoading, selectZoomUrl } from './selectors';
import { dashboardCreators } from './reducer';
import { connect } from 'react-redux';
import dashboardSaga from './saga';
import { injectIntl } from 'react-intl';
import { injectSaga } from 'redux-injectors';
import ClassSlider from './components/ClassSlider/ClassSlider';
import { DashboardContainer, ModalStyle, StyledDashboard } from './Dashboard.styles';
import AlertPopUp from './components/AlertPopUp/AlertPopUp';
import { routeConfig } from '@app/routeConfig';
import { logToCrashalytics, NOTIFICATION_TYPE, classsSessionsStatus } from 'tatvarnsdk/Web';

function Dashboard(props) {
  const {
    dispatchGetHomeScreenDetails,
    dispatchDeleteDocWithToken,
    dispatchRegisterZoom,
    categoriesWithClasses,
    loading,
    userDocId, //todo: otp view
    setuserDocId
  } = props;
  const history = useHistory();
  useEffect(() => {
    logToCrashalytics('Dashboard');
    dispatchGetHomeScreenDetails();
    if (userDocId) {
      dispatchDeleteDocWithToken(userDocId);
      setuserDocId(null);
    }
  }, []);
  const [popup, setPopup] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);

  const onClassClicked = async (session) => {
    setSelectedSession(session);
    if (!session.eligibileToJoin) {
      return setPopup({
        title: 'Eligibility!',
        description1: 'Your subscription does not allow you to join this class.',
        description2: 'Please upgrade your subscription or buy a classpass to join this class.',
        actionButtonText: 'Go To Subscription',
        notificationType: NOTIFICATION_TYPE.WARNING,
        action: 'Eligibility'
      });
    }
    if (!session.isGoodTimeToJoin) {
      return setPopup({
        title: 'Time!',
        description1: `${session.title} class has not started.`,
        description2: 'You can join the class 15 minutes before the scheduled time.',
        actionButtonText: 'OK',
        notificationType: NOTIFICATION_TYPE.DEFAULT,
        action: 'Time'
      });
    }
    if (session?.status === classsSessionsStatus.TEACHER_UNAVAILABLE) {
      return setPopup({
        title: 'Teacher Unavailable!',
        description1: 'We are very sorry for the inconvenience.',
        description2: 'Teacher of this class is not available. You can schedule/join other class of same type.',
        actionButtonText: 'OK',
        notificationType: NOTIFICATION_TYPE.WARNING
      });
    }
    setPopup({
      title: 'Join?',
      description1: `You are about to join ${session.title} class.`,
      description2: 'Press join to launch the class.',
      actionButtonText: 'Join',
      notificationType: NOTIFICATION_TYPE.SUCCESS,
      action: 'Join'
    });
  };

  const buttonAction = () => {
    if (popup?.action === 'Join') {
      dispatchRegisterZoom(selectedSession);
      return setPopup(null);
    }
    if (popup?.action === 'Eligibility') {
      history.push({ pathname: routeConfig.subscription.route });
    }
    setPopup(null);
    setSelectedSession(null);
  };

  useEffect(() => {
    const handleBackButton = (e) => {
      e.preventDefault();
    };
    window.addEventListener('popstate', handleBackButton);
    window.history.pushState(null, null, window.location.href);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <StyledDashboard>
      <DashboardContainer>
        {loading ? (
          <Loading />
        ) : (
          <>
            {(categoriesWithClasses || []).map((category) => {
              if (category.classes.length > 0) {
                return <ClassSlider category={category} key={category.name} onClicked={onClassClicked} />;
              }
            })}
          </>
        )}
        <ModalStyle isOpen={popup !== null} setIsOpen={setPopup}>
          <AlertPopUp
            visible={popup !== null}
            title={popup?.title}
            description1={popup?.description1}
            description2={popup?.description2}
            buttonAction={buttonAction}
            buttonText={popup?.actionButtonText}
            setPopup={setPopup}
            notificationType={popup?.notificationType}
          />
        </ModalStyle>
      </DashboardContainer>
    </StyledDashboard>
  );
}

const mapStateToProps = createStructuredSelector({
  categoriesWithClasses: selectCategoriesWithClasses(),
  loading: selectLoading(),
  zoomUrl: selectZoomUrl()
});

export function mapDispatchToProps(dispatch) {
  const { requestHomeScreenData, registerZoom, deleteDocToken } = dashboardCreators;
  return {
    dispatchGetHomeScreenDetails: () => dispatch(requestHomeScreenData()),
    dispatchRegisterZoom: (session) => dispatch(registerZoom(session)),
    dispatchDeleteDocWithToken: (docId) => dispatch(deleteDocToken(docId))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(injectIntl, withConnect, memo, injectSaga({ key: 'dashboard', saga: dashboardSaga }))(Dashboard);
